/* ==========================================================================
   The Kafe - Ultimate Freelance Marketplace Template
   ========================================================================== */
/*
* Author Name: TheMashaBrand
* Author URI: http://themeforest.net/user/themashabrand
* Website: http://www.themashabrand.com 
*/

/* ------------------------------------------------------------------- */
/* Table of Contents
---------------------------------------------------------------------- 
1. Reset & Basics                   - Line 28
2. Base Styles                      
    - Typogrphy Section             - Line 85
	- Links Section                 - Line 127
	- Lists Section                 - Line 157
	- Kafe Button Section           - Line 176
3. Job List 
    - Category List Section         - Line 297
	- Search Section                - Line 336
	- Job Section                   - Line 394
	- Pagination Section            - Line 526
	- Media Queries                 - Line 576
4. Sidebar - Affix Section          - Line 638
5. Form Signup                      - Line 669

---------------------------------------------------------------------- */ 

@import url('https://fonts.googleapis.com/css?family=Open+Sans|PT+Sans&display=swap');

/* #Reset & Basics (Inspired by E. Meyers)
================================================== */
html, body, div, span, applet, object, iframe, h1, h2, h3, h4, h5, h6, p, blockquote, pre, a, abbr, acronym, address, big, cite, code, del, dfn, em, img, ins, kbd, q, s, samp, small, strike, strong, sub, sup, tt, var, b, u, i, center, dl, dt, dd, fieldset, form, legend, table, caption, tbody, tfoot, thead, tr, th, td, article, aside, canvas, details, embed, figure, figcaption, footer, header, hgroup, menu, nav, output, ruby, section, summary, time, mark, audio, video {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  font: inherit;
  font-family: 'Open Sans', sans-serif;
  color: #282560;
  vertical-align: baseline; 
}
	
article, aside, details, figcaption, figure, footer, header, hgroup, menu, nav, section {
  display: block;
}

body {
  line-height: 1;
}

blockquote, q {
  quotes: none; 
}

blockquote:before, blockquote:after,
q:before, q:after {
  content: '';
  content: none;
}

table {
  border-collapse: collapse;
  border-spacing: 0; 
}

.no-select {
  user-select: none;
  -o-user-select:none;
  -moz-user-select: none;
  -khtml-user-select: none;
  -webkit-user-select: none; 
}

.flex-column {
  flex-direction: column;
}

/* ==========================================================================
   Base Styles
   ========================================================================== */
html, body {
  overflow-x: hidden;
  text-align: left;
  -webkit-font-smoothing: antialiased; /* Fix for webkit rendering */
 -webkit-text-size-adjust: 100%;
}

/* ------------------------------------------------------------------- */
/* Typography
---------------------------------------------------------------------- */
h1, h2, h3, h4, h5, h6 {
  font-family: 'PT Sans', sans-serif;
  color: #4096D2;
  letter-spacing: 1px;
}

h1 a, h2 a, h3 a, h4 a, h5 a, h6 a { 
  font-weight: inherit; 
}

h1 { font-size: 30px; line-height: 54px; }
h2 { font-size: 24px; line-height: 48px; }
h3 { font-size: 22px; line-height: 44px; }
h4 { font-size: 18px; line-height: 36px; }
h5 { font-size: 16px; line-height: 28px; }
h6 { font-size: 14px; line-height: 24px; }

p { 
  font-family: 'Open Sans', sans-serif;
  color: #282560;
  line-height: 24px;
  margin: 0;
  font-size: 14px;
  letter-spacing: 1px;
}
p img { margin: 0; }
p.lead { font-size: 21px; line-height: 27px; }

em { font-style: italic; }
strong { font-weight: 700; color: #333; }
small { font-size: 80%; }

.post-handle { font-family: 'PT Sans', sans-serif; }

a.brand-text { 
  font-family: 'Open Sans', sans-serif;
}

._loading_overlay_content, .brand-alt-text {
  color: #fff;
}

label.control-label {
  font-weight: 400;
}

/* ------------------------------------------------------------------- */
/* Links
---------------------------------------------------------------------- */
a { 
  color: #282560;
  text-decoration: none; 
  outline: 0; 
  -webkit-transition:0.5s all ease;
  -moz-transition:0.5s all ease;
  -o-transition:0.5s all ease;
  -ms-transition:0.5s all ease;
  transition:0.5s all ease; 
}

a:hover,
a:focus,
a:active,
a.active {
  text-decoration: none;
  outline: 0;
  -webkit-transition:0.5s all ease;
  -moz-transition:0.5s all ease;
  -o-transition:0.5s all ease;
  -ms-transition:0.5s all ease;
  transition:0.5s all ease;
}

p a, p a:visited { line-height: inherit; }

.brand-btn-outline {
  border-color: #E54135;
  color: #282560 !important;
  background-color: transparent;
  outline: 0; 
  -webkit-transition:0.5s all ease;
  -moz-transition:0.5s all ease;
  -o-transition:0.5s all ease;
  -ms-transition:0.5s all ease;
  transition:0.5s all ease; 
}

.brand-btn-outline:hover, .brand-btn-outline:hover span {
  background-color: transparent;
  color: #EF4137 !important;
  border-color: #EF4137;
  text-decoration: none;
  outline: 0;
  -webkit-transition:0.5s all ease;
  -moz-transition:0.5s all ease;
  -o-transition:0.5s all ease;
  -ms-transition:0.5s all ease;
  transition:0.5s all ease;
}

.brand-btn {
  color: #fff;
  background-color: #E54135;
  border-color: #fff;
}

.brand-btn:hover {
  color: #fff;
  background-color: #EF4137;
  border-color: #fff;
}

.brand-btn[disabled]:hover {
  color: #EF4137;
}

.brand-link:hover {
  color: #EF4137 !important;
}

.landing-btn:hover {
  background-color: rgb(227, 132, 123) !important;
  border-color: rgb(227, 132, 123) !important;
}

.landing-btn-outline:hover {
  border-color: rgb(227, 132, 123) !important;
}

.landing-btn-outline:hover span {
  color: rgb(227, 132, 123) !important;
}

.white-button-nav-link {
  width: 373px;
  height: 65px;
  background: #FFFFFF 0% 0% no-repeat padding-box;
  border-radius: 33px;
  opacity: 1;
}

.bold-button-nav-link {
  font-weight: bold !important;
}

#mobile-nav-wrapper {
  transition: margin .5s;
  margin: 0 0 0 -71vw;
}

#mobile-nav-wrapper.mobile-nav-wrapper-open {
  margin-left: 0;
}

/* ------------------------------------------------------------------- */
/* Modal
---------------------------------------------------------------------- */

.modal-body p {
  margin: 15px auto;
  text-align: center;
  font-size: 100%;
}

div.inner-step {
  margin: 0 20px;
}

.modal-body.step-three p, .modal-body.step-three h2 {
  margin: 0 auto;
}

.modal-header {
  text-align: center;
}

.modal-title.h4 {
  font-size: 140% !important;
}

.modal-footer {
  display: flex;
  justify-content: center;
}

.modal-footer button {
  margin: auto 25px;
}

.modal-footer a.brand-btn {
  color: white !important;
}

div.modal-body, div.modal-footer, div.modal-header {
  border-top: none;
  border-bottom: none;
}

.modal-content {
  border-radius: 10px !important;
}

.brand-modal {
  margin-top: 40vh !important;
  min-height: 100% !important;
}

div.fade.modal.show {
  opacity: 1 !important;
}

div.fade.modal-backdrop.show {
  opacity: 0.3 !important;
}

/* ------------------------------------------------------------------- */
/* Inputs (from XD Wireframes)
---------------------------------------------------------------------- */

label.xd-form-label {
  font-family: 'Open Sans';
  font-size: 30px !important;
  color: #282560 !important;
  line-height: 41px !important;
  letter-spacing: 0px;
  opacity: 1;
  font-weight: 400;
}

.xd-form-control {
  border-width: 3px;
  border-color: #282560;
  height: 54px;
  font-family: 'PT Sans';
  font-size: 20px;
  font-style: italic;
}

/* ------------------------------------------------------------------- */
/* Popovers
---------------------------------------------------------------------- */

div.fade.popover.show {
  opacity: 1 !important;
}

/* ------------------------------------------------------------------- */
/* Lists
---------------------------------------------------------------------- */
ul, ol { margin: 0; }
ul { list-style: none outside; }
ol { list-style: decimal; }
ol, ul.square, ul.circle, ul.disc { margin-left: 10px; }
ul.square { list-style: square outside; color: #3d464d;}
ul.circle { list-style: circle outside; color: #3d464d; }
ul.disc { list-style: disc outside; color: #3d464d;}
ul ul, ul ol,
ol ol, ol ul {}
ul ul li, ul ol li,
ol ol li, ol ul li { line-height: 24px;}
li { line-height: 24px; }
ul.large li { line-height: 21px; }
li p { line-height: 24px; }

.active-nav > div { 
  background-color: #D7C47A !important;
}

.category {
  background-color: #EFEFEF;
  color: black;
  width: 150px;
  padding: 0px 10px !important;
  text-align: center;
  margin-bottom: 3px;
  cursor: pointer;
  position: relative;
}

.category p {
  display: inline-block;
  margin: 0px;
  padding: 0px;
}

.closeCat {
  display: inline-block;
  line-height: 27px !important;
  margin-left: -75px;
  color: black;
  position: absolute;
}

.selected {
  background-color: #FF9901 !important;
  color: white !important;
}

.nav-item {
  margin: 4px 0px; 
  padding: 5px;
  background-color: #D9D9D9;
  color: white
}

.wallet {
  font-family: 'Abhaya Libre', serif;
}

.wallet td {
  font-size: 20px;
}

.wallet .redeem {
  width: 100%;
  min-height: 200px;
  background-color: #F3F3F3;
  padding: 15px;
}

.wallet .redeem-button {
    background-color: #EF4137;
    vertical-align: middle !important;
    min-height: 40px;
    color: white;
    text-align: center;
    font-weight: 800;
    cursor: pointer;
}

.wallet .redeem-button p {
  margin: auto 0 !important;
  vertical-align: middle;
}

.convert-input input {
  font-size: 18px;
  text-align: center;
  width: 95%;
}

.convert-input span {
  position: relative;
  margin: -48px;
}

.green-nav > div {
  background-color: #059342;
}

.dark-pink-nav > div, .dark-pink-nav em, .dark-pink-nav span {
  background-color: #EC008C;
  color: white !important;
}

.indigo-nav > div {
  background-color: #282760;
}

.indigo-nav em, .indigo-nav span {
  color: white !important;
}

.light-green-text {
  color: #76B04F;
}

.ad-user {
  height: 40px;
  width: 40px;
  margin-left: -10px;
  display: inline-block !important;
}

.answerCircle {
  background-color: #E7E6E6;
  border: none;
  color: #4CAF50;
  padding: 20px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 16px;
  margin: 4px 15px;
  border-radius: 100%;
  width: 60px;
  height: 60px;
  position: relative;
  cursor: pointer;
}

.answerCheck {
  font-size: 60px;
  height: 60px;
  position: absolute;
  left: 5px;
  top: 0px;
}

/* Arrow Steps Modified From: https://codepen.io/polinovskyi/pen/embZmw */

/* Breadcrups CSS */

.arrow-steps .step {
	font-size: 14px;
	text-align: center;
	color: #666;
	cursor: default;
	margin: 0 3px;
	padding: 10px 10px 10px 30px;
	min-width: 180px;
	float: left;
	position: relative;
	background-color: #d9e3f7;
	-webkit-user-select: none;
	-moz-user-select: none;
	-ms-user-select: none;
	user-select: none; 
  transition: background-color 0.2s ease;
}

.arrow-steps .step:after,
.arrow-steps .step:before {
	content: " ";
	position: absolute;
	top: 0;
	right: -17px;
	width: 0;
	height: 0;
	border-top: 19px solid transparent;
	border-bottom: 17px solid transparent;
	border-left: 17px solid #d9e3f7;	
	z-index: 2;
  transition: border-color 0.2s ease;
}

.arrow-steps .step:before {
	right: auto;
	left: 0;
	border-left: 17px solid #fff;	
	z-index: 0;
}

.arrow-steps .step:first-child:before {
	border: none;
}

.arrow-steps .step:first-child {
	border-top-left-radius: 4px;
	border-bottom-left-radius: 4px;
}

.arrow-steps .step span {
	position: relative;
}

.arrow-steps .step span:before {
	opacity: 0;
	content: "✔";
	position: absolute;
	top: -2px;
	left: -20px;
}

.arrow-steps .step.done span:before {
	opacity: 1;
	-webkit-transition: opacity 0.3s ease 0.5s;
	-moz-transition: opacity 0.3s ease 0.5s;
	-ms-transition: opacity 0.3s ease 0.5s;
	transition: opacity 0.3s ease 0.5s;
}

.arrow-steps .step.current {
	color: #fff;
	background-color: #23468c;
}

.arrow-steps .step.current:after {
	border-left: 17px solid #23468c;	
}

.step.current span {
  color: #fff !important;
}

.selected-cat {
  opacity: 0.5;
}

.admin-button {
  min-height: 50px;
  width: 100%;
  background-color: white;
  padding: 15px 20px 10px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  margin: 4px 2px;
  -webkit-transition-duration: 0.4s; /* Safari */
  transition-duration: 0.4s;
  cursor: pointer;
}

.admin-button p {
  font-size: 17px;
}

.admin-button-grey {
  border: 2px solid #e7e7e7;
}

.admin-button-grey:hover{
  background-color: #e7e7e7; 
}

#dropdown-handle {
  padding-top: 15px;
  color: black;
  background: none;
  border: none;
}

#cashtagImage {
  width: 150px;
  height: 150px;
  opacity: 0;
}

.css-10nd86i {
  margin: 10px 0px;
}

.css-vj8t7z {
  border-color: hsla(0, 0%, 80%, 0.59) !important;
}

/* Toggle switch from https://www.w3schools.com/howto/howto_css_switch.asp */

/* The switch - the box around the slider */
.switch {
  position: relative;
  display: inline-block;
  width: 50px;
  height: 20px;
}

/* Hide default HTML checkbox */
.switch input {
  opacity: 0;
  width: 0;
  height: 0;
}

/* The slider */
.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  -webkit-transition: .4s;
  transition: .4s;
}

.slider:before {
  position: absolute;
  content: "";
  height: 15px;
  width: 15px;
  left: 4px;
  bottom: 4px;
  background-color: white;
  -webkit-transition: .4s;
  transition: .4s;
}

input:checked + .slider {
  background-color: #2196F3;
}

input:focus + .slider {
  box-shadow: 0 0 1px #2196F3;
}

input:checked + .slider:before {
  -webkit-transform: translateX(26px);
  -ms-transform: translateX(26px);
  transform: translateX(26px);
}

/* Rounded sliders */
.slider.round {
  border-radius: 15px;
}

.slider.round:before {
  border-radius: 50%;
}

/* Facebook and Google Login Buttons */

.loginBtn {
  box-sizing: border-box;
  position: relative;
  width: 250px;
  margin: 0.2em;
  padding: 0 15px 0 46px;
  border: none;
  text-align: left;
  line-height: 34px;
  white-space: nowrap;
  border-radius: 0.2em;
  font-size: 16px;
  color: #FFF;
}
.loginBtn:before {
  content: "";
  box-sizing: border-box;
  position: absolute;
  top: 0;
  left: 0;
  width: 34px;
  height: 100%;
}
.loginBtn:focus {
  outline: none;
}
.loginBtn:active {
  box-shadow: inset 0 0 0 32px rgba(0,0,0,0.1);
}


/* Facebook */
.loginBtn--facebook {
  background-color: #4C69BA;
  background-image: linear-gradient(#4C69BA, #3B55A0);
  /*font-family: "Helvetica neue", Helvetica Neue, Helvetica, Arial, sans-serif;*/
  text-shadow: 0 -1px 0 #354C8C;
}
.loginBtn--facebook:before {
  border-right: #364e92 1px solid;
  background: url('https://s3-us-west-2.amazonaws.com/s.cdpn.io/14082/icon_facebook.png') 6px 6px no-repeat;
}
.loginBtn--facebook:hover,
.loginBtn--facebook:focus {
  background-color: #5B7BD5;
  background-image: linear-gradient(#5B7BD5, #4864B1);
}


/* Google */
.loginBtn--google {
  /*font-family: "Roboto", Roboto, arial, sans-serif;*/
  background: #DD4B39;
}
.loginBtn--google:before {
  border-right: #BB3F30 1px solid;
  background: url('https://s3-us-west-2.amazonaws.com/s.cdpn.io/14082/icon_google.png') 6px 6px no-repeat;
}
.loginBtn--google:hover,
.loginBtn--google:focus {
  background: #E74B37;
}

/* Separator */
.dropdown-menu .divider {
  background-color: #e5e5e5;
  height: 1px;
  margin: 0;
  overflow: hidden;
  width: 100%;
}

.padding-8 {
  padding: 8px;
}

@media screen and (min-width: 320px) and (max-width: 767px) and (orientation: landscape) {
  body.mobile {
    transform: rotate(-90deg);
    transform-origin: left top;
    width: 100vh;
    height: 100vw;
    overflow-x: visible;
    overflow-y: visible;
    position: absolute;
    top: 100%;
    left: 0;
  }
}